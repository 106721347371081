/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-assets', 'labelMore', 'Rādīt vairāk');
Labels.set('ui-assets', 'labelLess', 'Rādīt mazāk');
Labels.set(
    'ui-assets',
    'labelToggleContent',
    'Pašlaik redzami {visibleAssets} no {allAssets} saraksta vienumiem.'
);
/* eslint-enable max-len */
