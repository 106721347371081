/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-daterange', 'TODAY', 'Šodien');
Labels.set('ui-daterange', 'YESTERDAY', 'Vakar');
Labels.set('ui-daterange', 'CURRENT_WEEK', 'Šonedēļ');
Labels.set('ui-daterange', 'CURRENT_MONTH', 'Šomēnes');
Labels.set('ui-daterange', 'CURRENT_YEAR', 'No gada sākuma');
Labels.set('ui-daterange', 'PREV_YEAR', 'Iepriekšējais gads');
Labels.set('ui-daterange', 'PREV_WEEK', 'Iepriekšējā nedēļa');
Labels.set('ui-daterange', 'PREV_MONTH', 'Iepriekšējais mēnesis');
Labels.set('ui-daterange', 'PREV_MONTH_TODAY', 'No iepriekšējā mēneša sākuma');
Labels.set('ui-daterange', 'PERIOD_START', 'Perioda sākums');
Labels.set('ui-daterange', 'PERIOD_END', 'Perioda beigas');
Labels.set('ui-daterange', 'TIME_START', 'Laika sākums');
Labels.set('ui-daterange', 'TIME_END', 'Laika beigas');
/* eslint-enable max-len */
