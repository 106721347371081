/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-cookie-consent', 'title', 'Mūsu sīkdatnes – jūsu izvēle');
Labels.set(
    'ui-cookie-consent',
    'info',
    'Swedbank Grupas {entitiesLink} {country} izmanto {cookiesLink} (vai tamlīdzīgas tehnoloģijas), lai nodrošinātu jums personalizētu pieredzi un uzlabotu tīmekļa vietnes darbību. Plašākai informācijai skatiet mūsu {policyLink}.\n\nNospiežot uz “Pieņemt”, tu piekrīti neobligātajām analītiskajām un mērķēšanas sīkdatnēm, savukārt nospiežot uz “Atteikties”, tu atsakies no šīm sīkdatnēm. Tālāk varat arī pārvaldīt savu izvēli attiecībā uz neobligātajām sīkdatnēm. Savu piekrišanu jebkurā laikā varat atsaukt mūsu {usageLink}.'
);
Labels.set(
    'ui-cookie-consent',
    'disclaimer',
    'Dažas sīkdatnes ir nepieciešamas šīs tīmekļa vietnes darbībai un tās tiek apstrādātas, ņemot vērā mūsu leģitīmās intereses, tāpēc jūsu piekrišana netiek prasīta. Šajā tīmekļa vietnē tiek izmantotas trešo pušu sīkdatnes.'
);
Labels.set('ui-cookie-consent', 'accept', 'Apstiprināt');
Labels.set('ui-cookie-consent', 'decline', 'Atteikties');
Labels.set('ui-cookie-consent', 'saveChoice', 'Saglabāt manu izvēli');
Labels.set('ui-cookie-consent', 'myCookies', 'Izvēlēties sīkdatnes');
Labels.set('ui-cookie-consent', 'policyLinkLabel', 'sīkdatņu politiku');
Labels.set('ui-cookie-consent', {
    HELP_NECESSARY:
        'Ir nepieciešamas, lai tīmekļa vietne darbotos. Tās nevar izslēgt, tādēļ lietotāja piekrišana netiek prasīta.',
    HELP_ANALYTICAL:
        'Palīdz uzlabot tīmekļa vietnes darbību, uzskaitot vietnes apmeklējumu skaitu un avotus. Atsacīšanās gadījumā jūsu vietnes apmeklējums netiks iekļauts mūsu statistikā.',
    HELP_TARGETING:
        'Palīdz sniegt jums pielāgotu informāciju par mūsu produktiem un pakalpojumiem. Atsacīšanās gadījumā jums netiks piedāvāts personalizēts saturs.',
});
Labels.set('ui-cookie-consent', {
    CATEGORY_NECESSARY: 'Nepieciešamās',
    CATEGORY_ANALYTICAL: 'Analītiskās',
    CATEGORY_TARGETING: 'Mērķauditorijas atlases',
});
Labels.set('ui-cookie-consent', 'EE', 'Igaunija');
Labels.set('ui-cookie-consent', 'LV', 'Latvijā');
Labels.set('ui-cookie-consent', 'LT', 'Lietuva');
Labels.set('ui-cookie-consent', 'entities', 'uzņēmumi');
Labels.set('ui-cookie-consent', 'cookies', 'sīkdatnes');
Labels.set('ui-cookie-consent', 'language', 'Valodu');
Labels.set('ui-cookie-consent', 'usage', 'Sīkdatņu izmantošanas lapā');
/* eslint-enable max-len */
