import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-stepper', {
    month: {
        1: 'mēnesis',
        2: 'mēneši',
    },
    year: {
        1: 'gads',
        2: 'gadi',
    },
    increase: 'Palielināt',
    decrease: 'Samazināt',
    value: 'Vērtību',
});
