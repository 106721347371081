/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-textcounter', 'remaining', 'Atlikušo simbolu skaits:');
Labels.set(
    'ui-textcounter',
    'invalid',
    'Teksts ir pārāk garš (maks. %d rakstu zīmes).'
);
/* eslint-enable max-len */
