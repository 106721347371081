/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

// TOOD: translation might be incorrect
Labels.set(
    'ui-tabs',
    'arrowKeys',
    'Navigācija starp cilnēm ir iespējama ar bulttaustiņiem'
);
Labels.set('ui-tabs', 'selectTag', 'Izvēlieties cilni');
/* eslint-enable max-len */
