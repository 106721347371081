import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-monthpicker', 'months', [
    'Janvāris',
    'Februāris',
    'Marts',
    'Aprīlis',
    'Maijs',
    'Jūnijs',
    'Jūlijs',
    'Augusts',
    'Septembris',
    'Oktobris',
    'Novembris',
    'Decembris',
]);
Labels.set('ui-monthpicker', 'year', 'Gads');
Labels.set('ui-monthpicker', 'month', 'Mēnesis');
