/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('validation', 'input', 'Ievadiet derīgu vērtību.');
Labels.set('validation', 'date', 'Ievadiet derīgu vērtību');
Labels.set('validation', 'time', 'Ievadiet laiku.');
Labels.set('validation', 'range', 'Ievadītā vērtība nav derīga.');
Labels.set('validation', 'month', 'Ievadiet atbilstošu mēnesi.');
Labels.set('validation', 'datetime-local', 'Ievadiet datumu.');
Labels.set('validation', 'custom', 'Validācijas kļūda.');
Labels.set(
    'validation',
    'pattern',
    'Lūdzu, ievadiet vērtību pareizajā formātā.'
);
Labels.set(
    'validation',
    'rangeOverflow',
    'Vērtībai jābūt mazākai vai vienādai %s.'
);
Labels.set(
    'validation',
    'rangeUnderflow',
    'Vērtībai jābūt lielākai vai vienādai %s.'
);
Labels.set('validation', 'step', 'Solis ir %s, tuvākās vērtības ir %s un %s.');
Labels.set(
    'validation',
    'long',
    'Vērtība ir pārāk gara, maksimālais garums ir %s rakstzīmes.'
);
Labels.set(
    'validation',
    'short',
    'Vērtība ir par īsu, minimālais garums ir %s rakstzīmes.'
);
Labels.set('validation', 'type', 'Lūdzu, ievadiet derīgu vērtību.');
Labels.set('validation', 'email', 'Ievadiet e-pasta adresi.');
Labels.set('validation', 'url', 'Ievadiet lapas saiti.');
Labels.set('validation', 'value', 'Aizpildiet šo lauku.');

// User defined rule validations
Labels.set('validation', 'matches', 'Lauku vērtības nesakrīt.');
Labels.set(
    'validation',
    'checkboxes',
    'Lūdzu, atlasiet vismaz %s opciju(-as).'
);
Labels.set('validation', 'remote', 'Nepareiza atbilde no servera');
Labels.set('validation', 'maxfilesize', 'File(s) size limit exceeded %s Kb.'); // TODO: translate
Labels.set(
    'validation',
    'requiredMultiple',
    'Lūdzu, atlasiet vismaz %s lauku(s).'
);
/* eslint-enable max-len */
