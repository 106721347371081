/* eslint-disable max-len */

import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-feedback', 'MIN_RATE', 'Grūti');
Labels.set('ui-feedback', 'MAX_RATE', 'Viegli');
Labels.set('ui-feedback', 'POSITIVE', 'Jā');
Labels.set('ui-feedback', 'NEGATIVE', 'Nē');
Labels.set('ui-feedback', 'CONFIRM', 'Sūtīt');
Labels.set(
    'ui-feedback',
    'TEXTAREA_LABEL',
    'Ko mēs varam izdarīt, lai uzlabotu šo procesu?'
);
Labels.set(
    'ui-feedback',
    'HINT_MESSAGE',
    'Šī aptauja ir anonīma. Tās rezultātus mēs izmantosim mūsu servisa uzlabošanai.'
);
Labels.set('ui-feedback', 'SUCCESS_MESSAGE', 'Paldies par atsaucību!');
Labels.set('ui-feedback', 'ERROR_MESSAGE', 'Feedback sending failed');
