/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-calendar', 'weekDays', ['P', 'O', 'T', 'C', 'Pk', 'S', 'Sv']);
Labels.set('ui-calendar', 'weekDaysLong', [
    'Pirmdiena',
    'Otrdiena',
    'Trešdiena',
    'Ceturtdiena',
    'Piektdiena',
    'Sestdiena',
    'Svētdiena',
]);
Labels.set('ui-calendar', 'prevMonth', 'Iepriekšējais mēnesis');
Labels.set('ui-calendar', 'nextMonth', 'Nākamajā mēnesī');
Labels.set(
    'ui-calendar',
    'arrowKeys',
    'Bulttaustiņi var pārvietoties pa datumiem'
);
/* eslint-enable max-len */
