import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-comparison-cards', 'compareDetails', 'Salīdzināt detaļas');
Labels.set('ui-comparison-cards', 'available', 'Pieejams');
Labels.set('ui-comparison-cards', 'notAvailable', 'Nav pieejams');
Labels.set('ui-comparison-cards', 'comparisonItem1', 'Salīdzināmais 1');
Labels.set('ui-comparison-cards', 'comparisonItem2', 'Salīdzināmais 2');
Labels.set(
    'ui-comparison-cards',
    'selectProductsCompare',
    'Izvēlieties, kuras preces salīdzināt.'
);
